import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import './Login.css'; // Import custom CSS
import './Auth.css'; // Import custom CSS

function App() {
  const [file, setFile] = useState(null);
  const [contentNumber, setContentNumber] = useState('');
  const [description, setDescription] = useState('');
  const [assetClassification, setAssetClassification] = useState('Agenda');
  const [targetedAudience, setTargetedAudience] = useState([]);
  const [promotionalUse, setPromotionalUse] = useState('Promotional');
  const [geography, setGeography] = useState([]);
  const [methodOfDistribution, setMethodOfDistribution] = useState([]);
  const [changeDescription, setChangeDescription] = useState('');
  const [changeReason, setChangeReason] = useState('');
  const [reviewerApproverChecklist, setReviewerApproverChecklist] = useState(false);
  const [containsHumanClinicalData, setContainsHumanClinicalData] = useState(false);
  const [containsClaims, setContainsClaims] = useState(false);
  const [containsMarketingData, setContainsMarketingData] = useState(false);
  const [containsReimbursementData, setContainsReimbursementData] = useState(false);
  const [containsEngineeringResearchData, setContainsEngineeringResearchData] = useState(false);
  const [uploadedContent, setUploadedContent] = useState(null);
  const [searchContentNumber, setSearchContentNumber] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [assessmentResult, setAssessmentResult] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [activeTab, setActiveTab] = useState('Upload');
  const [product, setProduct] = useState('Product A'); // New state variable for Product picklist
  const [contentList, setContentList] = useState([]);
  const [filters, setFilters] = useState({
    assetClassification: '',
    targetedAudience: '',
    promotionalUse: '',
    geography: '',
    methodOfDistribution: '',
    product: ''
  });
  const [expandedRows, setExpandedRows] = useState({});

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      axios.get('http://localhost:8000/admin/users', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        setIsAdmin(response.data.some(user => user.username === username && user.is_admin));
      })
      .catch(error => {
        console.error(error);
      });
    }
  }, [isLoggedIn]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'http://localhost:8000/token',
        new URLSearchParams({
          username: username,
          password: password,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      localStorage.setItem('token', response.data.access_token);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setIsAdmin(false);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('description', description);
    formData.append('asset_classification', assetClassification);
    formData.append('targeted_audience', targetedAudience.join(','));
    formData.append('promotional_use', promotionalUse);
    formData.append('geography', geography.join(','));
    formData.append('method_of_distribution', methodOfDistribution.join(','));
    formData.append('change_description', changeDescription);
    formData.append('change_reason', changeReason);
    formData.append('reviewer_approver_checklist', reviewerApproverChecklist);
    formData.append('contains_human_clinical_data', containsHumanClinicalData);
    formData.append('contains_claims', containsClaims);
    formData.append('contains_marketing_data', containsMarketingData);
    formData.append('contains_reimbursement_data', containsReimbursementData);
    formData.append('contains_engineering_research_data', containsEngineeringResearchData);
    formData.append('product', product); // Include Product in the form data

    try {
      const response = await axios.post('http://localhost:8000/upload/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setUploadedContent(response.data);
      setContentNumber(response.data.content_number);
      console.log(response.data);
    } catch (error) {
      console.error('There was a problem with the upload operation:', error);
    }
  };

  const handleSearch = async (contentNumberToSearch) => {
    try {
      const response = await fetch(`http://localhost:8000/content/${contentNumberToSearch || searchContentNumber}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Content not found');
      }
      const data = await response.json();
      setSearchResult(data);
      setContentNumber(data.content_number);
      setDescription(data.description);
      setAssetClassification(data.asset_classification);
      setTargetedAudience(data.targeted_audience.split(','));
      setPromotionalUse(data.promotional_use);
      setGeography(data.geography.split(','));
      setMethodOfDistribution(data.method_of_distribution.split(','));
      setChangeDescription(data.change_description);
      setChangeReason(data.change_reason);
      setReviewerApproverChecklist(data.reviewer_approver_checklist);
      setContainsHumanClinicalData(data.contains_human_clinical_data);
      setContainsClaims(data.contains_claims);
      setContainsMarketingData(data.contains_marketing_data);
      setContainsReimbursementData(data.contains_reimbursement_data);
      setContainsEngineeringResearchData(data.contains_engineering_research_data);
      setProduct(data.product); // Set the product state from the retrieved data
      setAssessmentResult(data.assessment_result || "Assessment not performed");
      setActiveTab('Upload'); // Switch to the Assess tab to display the search result
    } catch (error) {
      console.error('There was a problem with the search operation:', error);
    }
  };

  const handleAssess = async () => {
    try {
      const response = await fetch(`http://localhost:8000/assess/${contentNumber}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      setAssessmentResult(data.assessment_result);
      await axios.put(`http://localhost:8000/content/${contentNumber}/assessment_result`, {
        assessment_result: data.assessment_result
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
    } catch (error) {
      console.error('There was a problem with the assessment operation:', error);
    }
  };

  const renderAssessmentResults = () => {
    if (!assessmentResult) return <p>Assessment not performed</p>;
    return assessmentResult.split('^').map((item, index) => (
      <div key={index} className="assessment-item">
        {item.trim() && <p>{item.trim()}</p>}
      </div>
    ));
  };

  const fetchContentList = async () => {
    try {
      const response = await axios.get('http://localhost:8000/content/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setContentList(response.data);
    } catch (error) {
      console.error('Error fetching content list', error);
    }
  };

  useEffect(() => {
    if (activeTab === 'Library') {
      fetchContentList();
    }
  }, [activeTab]);

  const applyFilters = async () => {
    try {
      const response = await axios.get('http://localhost:8000/content/', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        params: filters
      });
      setContentList(response.data);
    } catch (error) {
      console.error('Error applying filters', error);
    }
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value
    });
  };

  const toggleRow = (contentNumber) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [contentNumber]: !prevState[contentNumber]
    }));
  };

  const renderContentList = () => (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>Content Number</th>
          <th>Description</th>
          <th>Asset Classification</th>
          <th>Targeted Audience</th>
          <th>Promotional Use</th>
          <th>Geography</th>
          <th>Method of Distribution</th>
          <th>Product</th>
          <th>Assessment Result</th>
        </tr>
      </thead>
      <tbody>
        {contentList.map(content => (
          <React.Fragment key={content.content_number}>
            <tr>
              <td>
                <a href="#" onClick={() => handleSearch(content.content_number)}>
                  {content.content_number}
                </a>
              </td>
              <td>{content.description}</td>
              <td>{content.asset_classification}</td>
              <td>{content.targeted_audience}</td>
              <td>{content.promotional_use}</td>
              <td>{content.geography}</td>
              <td>{content.method_of_distribution}</td>
              <td>{content.product}</td>
              <td>
                <div style={{ maxWidth: '600px' }}>
                  {expandedRows[content.content_number]
                    ? content.assessment_result
                    : `${content.assessment_result.split('\n')[0]}...`}
                </div>
                <button onClick={() => toggleRow(content.content_number)}>
                  {expandedRows[content.content_number] ? 'Show less' : 'Show more'}
                </button>
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );

  const renderContentViewer = (filePath) => {
    return <embed src={`http://localhost:8000/${filePath}`} width="100%" height="2000px" type="application/pdf" />;
  };

  return (
    <div className="app-container">
      {isLoggedIn ? (
        <>
          <header className="app-header">
            <img src="/sherlock.png" className="app-logo" alt="logo" />
            <form onSubmit={(e) => { e.preventDefault(); handleSearch(); }} className="search-form">
              <input
                type="text"
                className="search-input"
                value={searchContentNumber}
                onChange={(e) => setSearchContentNumber(e.target.value)}
                placeholder="Content number"
              />
              <button type="submit" className="search-button">
                <i className="fas fa-search"></i>
              </button>
            </form>
            <div className="user-info">
              <span className="username">User: {username}</span>
              <button onClick={handleLogout} className="logout-button">Logout</button>
            </div>
          </header>
          <div className="tabs">
            <div className={`tab ${activeTab === 'Upload' ? 'active' : ''}`} onClick={() => setActiveTab('Upload')}>Upload</div>
            <div className={`tab ${activeTab === 'Assess' ? 'active' : ''}`} onClick={() => setActiveTab('Assess')}>Assess</div>
            <div className={`tab ${activeTab === 'Library' ? 'active' : ''}`} onClick={() => setActiveTab('Library')}>Library</div>
          </div>
          <div className="app-content">
            {activeTab === 'Upload' && (
              <div className="container-fluid mt-3">
                <div className="row">
                  <div className="col-sm-3 bg-white text-black metadata-section">
                    <div className="header-bar">Upload Content and Metadata</div>
                    <form onSubmit={handleUpload}>
                      <div className="parameter">
                        <label>File:</label>
                        <input type="file" id="file-input" onChange={(e) => setFile(e.target.files[0])} required />
                      </div>
                      <div className="parameter">
                        <label>Content Number:</label>
                        <input type="text" value={contentNumber} readOnly />
                      </div>
                      <div className="parameter">
                        <label>Description:</label>
                        <textarea
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          placeholder="Enter description/purpose of the content"
                          rows="4"
                          cols="30"
                        />
                      </div>
                      <div className="parameter">
                        <label>Asset Classification:</label>
                        <select value={assetClassification} onChange={(e) => setAssetClassification(e.target.value)}>
                          <option value="Agenda">Agenda</option>
                          <option value="Video">Video</option>
                          <option value="Webinar">Webinar</option>
                          <option value="Presentation">Presentation</option>
                        </select>
                      </div>
                      <div className="parameter">
                        <label>Targeted Audience:</label>
                        <select multiple={true} value={targetedAudience} onChange={(e) => setTargetedAudience([...e.target.selectedOptions].map(o => o.value))}>
                          <option value="Healthcare professionals">Healthcare professionals</option>
                          <option value="Patient">Patient</option>
                          <option value="Sales and Marketing">Sales and Marketing</option>
                          <option value="Internal users">Internal users</option>
                        </select>
                      </div>
                      <div className="parameter">
                        <label>Promotional Use / Non-promotional use:</label>
                        <select value={promotionalUse} onChange={(e) => setPromotionalUse(e.target.value)}>
                          <option value="Promotional">Promotional</option>
                          <option value="Non-promotional">Non-promotional</option>
                        </select>
                      </div>
                      <div className="parameter">
                        <label>Geography:</label>
                        <select multiple={true} value={geography} onChange={(e) => setGeography([...e.target.selectedOptions].map(o => o.value))}>
                          <option value="Global">Global</option>
                          <option value="US">US</option>
                          <option value="EU">EU</option>
                          <option value="APAC">APAC</option>
                          <option value="LATAM">LATAM</option>
                        </select>
                      </div>
                      <div className="parameter">
                        <label>Method of Distribution:</label>
                        <select multiple={true} value={methodOfDistribution} onChange={(e) => setMethodOfDistribution([...e.target.selectedOptions].map(o => o.value))}>
                          <option value="Social Media">Social Media</option>
                          <option value="Conferences">Conferences</option>
                          <option value="Training platforms">Training platforms</option>
                          <option value="CRM">CRM</option>
                        </select>
                      </div>
                      <div className="parameter">
                        <label>Product:</label>
                        <select value={product} onChange={(e) => setProduct(e.target.value)}>
                          <option value="Product A">Product A</option>
                          <option value="Product B">Product B</option>
                          <option value="Product C">Product C</option>
                        </select>
                      </div>
                      <div className="parameter">
                        <label>Change Description:</label>
                        <textarea
                          value={changeDescription}
                          onChange={(e) => setChangeDescription(e.target.value)}
                          placeholder="Enter change description"
                          rows="4"
                          cols="30"
                        />
                      </div>
                      <div className="parameter">
                        <label>Change Reason:</label>
                        <textarea
                          value={changeReason}
                          onChange={(e) => setChangeReason(e.target.value)}
                          placeholder="Enter change reason"
                          rows="4"
                          cols="30"
                        />
                      </div>
                      <div className="parameter">
                        <label>Reviewer / Approver Checklist:</label>
                      </div>
                      <div className="parameter">
                        <label>Does this content contain Human Clinical Data?</label>
                        <input
                          type="checkbox"
                          checked={containsHumanClinicalData}
                          onChange={(e) => setContainsHumanClinicalData(e.target.checked)}
                        />
                      </div>
                      <div className="parameter">
                        <label>Does this content contain any claims?</label>
                        <input
                          type="checkbox"
                          checked={containsClaims}
                          onChange={(e) => setContainsClaims(e.target.checked)}
                        />
                      </div>
                      <div className="parameter">
                        <label>Does this content contain marketing data?</label>
                        <input
                          type="checkbox"
                          checked={containsMarketingData}
                          onChange={(e) => setContainsMarketingData(e.target.checked)}
                        />
                      </div>
                      <div className="parameter">
                        <label>Does this content contain Reimbursement data?</label>
                        <input
                          type="checkbox"
                          checked={containsReimbursementData}
                          onChange={(e) => setContainsReimbursementData(e.target.checked)}
                        />
                      </div>
                      <div className="parameter">
                        <label>Does this content contain engineering or research data?</label>
                        <input
                          type="checkbox"
                          checked={containsEngineeringResearchData}
                          onChange={(e) => setContainsEngineeringResearchData(e.target.checked)}
                        />
                      </div>
                      <button type="submit" className="btn btn-dark btn-block">Upload</button>
                    </form>
                    {uploadedContent && (
                      <div className="mt-4">
                        <h2 className="header-bar">Uploaded Content</h2>
                        <p><strong>Content Number:</strong> {uploadedContent.content_number}</p>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-9 bg-white text-black content-section">
                    <div>
                      <h2 className="header-bar">Uploaded Content</h2>
                      {searchResult && (
                        <div>
                          <div className="mt-3 content-container">
                          <div className="content-viewer">
                                      {searchResult.file_path ? (
                                        renderContentViewer(searchResult.file_path)
                                      ) : (
                                        <p>No file available</p>
                                      )}
                                    </div>
                          </div>
                          <button onClick={handleAssess} className="btn btn-primary mt-3">Assess Content</button>
                          <div className="mt-4 assessment-results">
                            <h2 className="header-bar">Assessment Results</h2>
                            {renderAssessmentResults()}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'Assess' && (
              <div className="container-fluid mt-3">
                <div className="row">
                  <div className="col-sm-12 bg-white text-black content-section">
                    <div>
                      <h2 className="header-bar">Assess Content</h2>
                      {searchResult && (
                        <div>
                          <div className="mt-3 content-assess-container">
                          <div className="content-assess-viewer">
                                      {searchResult.file_path ? (
                                        renderContentViewer(searchResult.file_path)
                                      ) : (
                                        <p>No file available</p>
                                      )}
                                    </div>
                            
                          </div>
                          <button onClick={handleAssess} className="btn btn-primary mt-3">Assess Content</button>
                          <div className="mt-4 assessment-results">
                            <h2 className="header-bar">Assessment Results</h2>
                            {renderAssessmentResults()}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {activeTab === 'Library' && (
              <div className="library-container">
                <div className="library-filters">
                  <h2>Filters</h2>
                  <div className="parameter">
                    <label>Asset Classification:</label>
                    <select name="assetClassification" value={filters.assetClassification} onChange={handleFilterChange}>
                      <option value="">All</option>
                      <option value="Agenda">Agenda</option>
                      <option value="Video">Video</option>
                      <option value="Webinar">Webinar</option>
                      <option value="Presentation">Presentation</option>
                    </select>
                  </div>
                  <div className="parameter">
                    <label>Targeted Audience:</label>
                    <select name="targetedAudience" value={filters.targetedAudience} onChange={handleFilterChange}>
                      <option value="">All</option>
                      <option value="Healthcare professionals">Healthcare professionals</option>
                      <option value="Patient">Patient</option>
                      <option value="Sales and Marketing">Sales and Marketing</option>
                      <option value="Internal users">Internal users</option>
                    </select>
                  </div>
                  <div className="parameter">
                    <label>Promotional Use:</label>
                    <select name="promotionalUse" value={filters.promotionalUse} onChange={handleFilterChange}>
                      <option value="">All</option>
                      <option value="Promotional">Promotional</option>
                      <option value="Non-promotional">Non-promotional</option>
                    </select>
                  </div>
                  <div className="parameter">
                    <label>Geography:</label>
                    <select name="geography" value={filters.geography} onChange={handleFilterChange}>
                      <option value="">All</option>
                      <option value="Global">Global</option>
                      <option value="US">US</option>
                      <option value="EU">EU</option>
                      <option value="APAC">APAC</option>
                      <option value="LATAM">LATAM</option>
                    </select>
                  </div>
                  <div className="parameter">
                    <label>Method of Distribution:</label>
                    <select name="methodOfDistribution" value={filters.methodOfDistribution} onChange={handleFilterChange}>
                      <option value="">All</option>
                      <option value="Social Media">Social Media</option>
                      <option value="Conferences">Conferences</option>
                      <option value="Training platforms">Training platforms</option>
                      <option value="CRM">CRM</option>
                    </select>
                  </div>
                  <div className="parameter">
                    <label>Product:</label>
                    <select name="product" value={filters.product} onChange={handleFilterChange}>
                      <option value="">All</option>
                      <option value="Product A">Product A</option>
                      <option value="Product B">Product B</option>
                      <option value="Product C">Product C</option>
                    </select>
                  </div>
                  <button onClick={applyFilters} className="btn btn-dark btn-block">Apply Filters</button>
                </div>
                <div className="library-content">
                  <h2>Content List</h2>
                  {renderContentList()}
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="login-container d-flex justify-content-center align-items-center">
          <div className="auth-details-sec">
            <div className="auth-logo-sec">
              <img src="/logo.png" className="auth-logo" alt="logo" />
              <h1 className="text-center mb-4">SHERLOCK</h1>
            </div>
            <form onSubmit={handleLogin}>
              <div className="parameter">
                <label>Username:</label>
                <input type="text" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} required />
              </div>
              <div className="parameter">
                <label>Password:</label>
                <input type="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} required />
              </div>
              <button type="submit" className="btn btn-dark btn-block">Login</button>
            </form>
          </div>
        </div>
      )}
      <footer className="app-footer">
        <p>&copy; Svasti 2024</p>
      </footer>
    </div>
  );
}

export default App;
